import React from 'react';
import { Link, graphql } from 'gatsby';
import Container from 'react-bootstrap/Container';
import Content, { HTMLContent, ContentType } from '../components/Content';

import Layout from '../components/Layout';

export const IndexPageTemplate = ({
  image,
  heading,
  subheading,
  quote,
  signature,
  buttonsImage,
  buttonsText,
  content,
  contentComponent,
}: IndexPageTemplateType) => {
  const PageContent = contentComponent || Content;
  heading = heading.replace('ESSEN', '<span class="text-primary">ESSEN</span>');

  return (
    <>
      <Container
        fluid
        className="container-full-width container-index-image mb-5"
        style={{
          backgroundImage: `url(${
            !!image.childImageSharp ? image.childImageSharp.fluid.src : image
            })`
        }}
      >
        <Container className="container-index-intro pb-5">
          <div className="container-index-intro-box">
            <div className="container-index-intro-content p-3">
              <h1 dangerouslySetInnerHTML={{ __html: heading }} />
              <h3>{subheading}</h3>
              <p className="font-italic">{quote}</p>
              {buttonsImage && buttonsImage.map((button, idx) => (
                <>
                  {button.link.startsWith('http')
                    ? <a href={button.link} target="_blank" className={`btn mr-2 mt-2 ${button.style}`}>{button.text}</a>
                    : <Link key={`button-image-${idx}`} to={`/${button.link}`} className={`btn mr-2 mt-2 ${button.style}`}>{button.text}</Link>
                  }
                </>
              ))}
            </div>
          </div>
        </Container>
      </Container>
      <Container>
        <div id="mehr-erfahren" className="rel-anchor" />
        <PageContent content={content} />
        <p className="signature">{signature}</p>
        {buttonsText && buttonsText.map((button, idx) => (
          <>
            {button.link.startsWith('http')
              ? <a href={button.link} target="_blank" className={`btn mr-2 mt-2 ${button.style}`}>{button.text}</a>
              : <Link key={`button-image-${idx}`} to={`/${button.link}`} className={`btn mr-2 mt-2 ${button.style}`}>{button.text}</Link>
            }
          </>
        ))}
      </Container>
    </>
  );
};

type IndexPageTemplateType = {
  image: any | string,
  heading: string,
  subheading: string,
  quote: string,
  signature: string,
  buttonsImage: [{
    text: string,
    link: string,
    style?: string,
  }],
  buttonsText: [{
    text: string,
    link: string,
    style?: string,
  }],
  content?: string,
  contentComponent?: { (data: ContentType): any },
};

const IndexPage = ({ data }: IndexPageType) => {
  const { frontmatter, html } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        quote={frontmatter.quote}
        signature={frontmatter.signature}
        buttonsImage={frontmatter.buttonsImage}
        buttonsText={frontmatter.buttonsText}
        contentComponent={HTMLContent}
        content={html}
      />
    </Layout>
  );
};

type IndexPageType = {
  data: {
    markdownRemark: {
      html: string,
      frontmatter: {
        image: {
          childImageSharp: {
            fluid: any,
          },
        },
        heading: string,
        subheading: string,
        quote: string,
        signature: string,
        buttonsImage: [{
          text: string,
          link: string,
          style?: string,
        }],
        buttonsText: [{
          text: string,
          link: string,
          style?: string,
        }],
      },
    },
  },
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      html
      frontmatter {
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        quote
        signature
        buttonsImage {
          text
          link
          style
        }
        buttonsText {
          text
          link
          style
        }
      }
    }
  }
`;
